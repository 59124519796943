<template>
<div class="relative">
        <div :class="[{'rounded-t-[0px] sm:rounded-t-[unset] sm:rounded-b-[0px]': show}, 'bg-white p-3 shadow-[0_0_5px_0] shadow-gray-normal rounded-[10px] text-start w-full']"
             v-click-outside="() => {show = false}">
            <div class="flex gap-2 cursor-pointer select-none min-w-max justify-between items-center"
                 @click="() => {show = !show}">
                <div>
                    <div class="font-bold">{{ 'Udhëtarët' }}</div>
                    <p class="text-gray-400">{{mainStore.getTravelersInfo}}</p>
                </div>
                <nuxt-icon name="chevron-down-solid" :class="[(show ? 'chevron-up' : 'chevron-down'), 'text-xl sm:text-2xl']" filled/>
            </div>
            <transition name="slide-fade">
                <div v-if="show" :class="`text-start z-[30] min-w-[250px] absolute left-0 bg-white bottom-[4.5rem] sm:bottom-[unset] sm:top-16 w-full shadow-[0_0_5px_0] shadow-gray-normal rounded-b-0 rounded-t-[10px] sm:rounded-t-[0px] sm:rounded-b-[10px] flex flex-col max-h-[450px] p-5`">
                    <h2 class="text-xl">Udhëtaret</h2>
                    <div class="flex justify-between mt-2  border-b border-gray-light pb-2">
                        <div>
                            Të rritur
                            <p class="text-gray-400">12+</p>
                        </div>
                        <div class="flex gap-2 items-center">
                            <button @click="handleTravelerChange(adultNo - 1)" class="rounded-full w-8 h-8 bg-white shadow-[0_0_5px_0] shadow-gray-200" :disabled="adultNo < 2">
                                -
                            </button>
                            <div>
                                {{adultNo}}
                            </div>
                            <button @click="handleTravelerChange(adultNo + 1)" class="rounded-full w-8 h-8 bg-white shadow-[0_0_5px_0] shadow-gray-200" :disabled="adultNo === 5">
                                +
                            </button>
                        </div>
                    </div>
                    <div class="flex justify-between mt-2 border-b border-gray-light pb-2">
                        <div>
                            Fëmijë
                            <p class="text-gray-400">2 - 11.9</p>
                        </div>
                        <div class="flex gap-2 items-center">
                            <button @click="handleChildChange(childrensNo - 1)" class="rounded-full w-8 h-8 bg-white shadow-[0_0_5px_0] shadow-gray-200" :disabled="childrensNo < 1">
                                -
                            </button>
                            <div>
                                {{childrensNo}}
                            </div>
                            <button @click="handleChildChange(childrensNo + 1)" class="rounded-full w-8 h-8 bg-white shadow-[0_0_5px_0] shadow-gray-200" :disabled="childrensNo === 5">
                                +
                            </button>
                        </div>
                    </div>
                    <div class="flex justify-between mt-2">
                        <div>
                            Bebe
                            <p class="text-gray-400">0 - 1.9</p>
                        </div>
                        <div class="flex gap-2 items-center">
                            <button @click="handleInfantChange(infantsNo - 1)" class="rounded-full w-8 h-8 bg-white shadow-[0_0_5px_0] shadow-gray-200" :disabled="infantsNo < 1">
                                -
                            </button>
                            <div>
                                {{infantsNo}}
                            </div>
                            <button @click="handleInfantChange(infantsNo + 1)" class="rounded-full w-8 h-8 bg-white shadow-[0_0_5px_0] shadow-gray-200" :disabled="infantsNo === 5">
                                +
                            </button>
                        </div>
                    </div>
<!--                    <div class="max-h-[240px] w-full overflow-auto pr-2">-->
<!--                        <div v-for="(child, index) in selectedChildren" @click="handleChildRemove(index)"-->
<!--                             :key="child.age"-->
<!--                             class="flex justify-between mt-3 items-center">-->
<!--                            <div>-->
<!--                                {{ childType(child.age) }}-->
<!--                                <p class="text-gray-400">-->
<!--                                    {{child.title}}-->
<!--                                </p>-->
<!--                            </div>-->
<!--                            <div class="w-5 h-5 text-white rounded-full bg-gray-normal relative cursor-pointer">-->
<!--                                <span class="absolute-center">-->
<!--                                    <nuxt-icon name="xmark-solid" class="text-xs"/>-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <dropdown-->
<!--                        iconClass="text-sm"-->
<!--                        class="mt-5 max-w-[200px] w-full self-center"-->
<!--                        :options="childOptions"-->
<!--                        positon="top-10"-->
<!--                        @option-changed="handleChildSelect"-->
<!--                    >-->
<!--                        <template #title="{selected}">-->
<!--                            <transition name="fade" mode="out-in">-->
<!--                                <div class="text-primary text-start ml-3 font-bold">Shto Fëmijë</div>-->
<!--                            </transition>-->
<!--                        </template>-->
<!--                        <template #default="{option}" class="text-primary">-->
<!--                            <div class="flex justify-between px-3">-->
<!--                                <div>-->
<!--                                    {{childType(option.age)}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{ option.title }}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </dropdown>-->
                </div>
            </transition>
        </div>
</div>

</template>
<script setup lang="ts">
import Dropdown from "~/components/Dropdown.vue";
interface ChildInterface {
    age: number
    title: string
}
defineProps(['options', 'selected', 'positon']);
const emit = defineEmits(['optionChanged']);
const show = ref(false);
const selectedChildren = ref<ChildInterface[]>([]);
const mainStore = useMainStore();

const childOptions: ChildInterface[] = [
    {age: 0, title: '0-11 muaj'},
    {age: 1, title: '1 vjeç'},
    {age: 2, title: '2 vjeç'},
    {age: 3, title: '3 vjeç'},
];
const adultNo = ref(1);
const childrensNo = ref(0);
const infantsNo = ref(0);
const childType = (age: number) => {
    if(age < 2){
        return 'Foshnjë'
    }
    return 'Fëmijë';
}

// const handleChildRemove = (index: number) => {
//     selectedChildren.value.splice(index, 1);
// }


const travelerPayload = computed(() => {
    // const infants = selectedChildren.value.filter(c => c.age < 2).length
    // const childrenNo = selectedChildren.value.length - infants;
    return {
        adults: adultNo.value,
        infants: infantsNo.value,
        children: childrensNo.value
    }
})

const modifyParams = () => {
    mainStore.travelersNumber = travelerPayload.value;
}

// const handleChildSelect = (child: ChildInterface) => {
//     selectedChildren.value.push(child);
//     modifyParams()
// }

const handleTravelerChange = (value: number) => {
    adultNo.value = value;
    modifyParams()
}
const handleInfantChange = (value: number) => {
    infantsNo.value = value;
    modifyParams()
}
const handleChildChange = (value: number) => {
    childrensNo.value = value;
    modifyParams()
}

const setParamsTraveler = () => {
    const {adults, infants, children} = mainStore.travelersNumber;
    adultNo.value = adults;
    infantsNo.value = infants;
    childrensNo.value = children;
    // for(let x = 0; x < infants; x++){
    //     selectedChildren.value.push(childOptions[0]);
    // }
    //
    // for(let x = 0; x < children; x++){
    //     selectedChildren.value.push(childOptions[2]);
    // }
}

watch(() => mainStore.travelersNumber, (travelers) => {
    console.log('travelers::', travelers)
})

onMounted(() => {
    // console.log('mainStore.travelersNumber::', mainStore.travelersNumber)
    setParamsTraveler()
})


</script>
<style scoped>

</style>
