<template>
    <div class="relative">
        <input-skeleton :class="['relative cursor-pointer !p-0', {'!rounded-b-[0]': show}, {'border border-primary': showError}]" v-click-outside="hideNights">
            <div @click="showNights" class="w-full h-full flex items-center justify-center">
                {{ displayNights(mainStore.selectedNights) || 'Netët' }}
            </div>
            <transition name="slide-fade">
                <div v-if="show" class="z-[30] absolute bg-white left-0 top-[50px] sm:top-[70px] w-full shadow-[0_0_5px_0] shadow-gray-normal rounded-b-[10px] flex flex-col text-center pt-2.5 gap-2.5 overflow-auto max-h-[250px]">
                    <div v-for="night in currNights" @click="handleSelect(night)" class="cursor-pointer py-2.5 hover:bg-secondary/20 w-full">
                        {{displayNights(night)}}
                    </div>
                </div>
            </transition>
        </input-skeleton>
        <tooltip class="min-w-max" @tooltipRemoved="handleTooltipRemoved" :trigger="triggerError" :timeout="1000" theme="error" message="Ju lutem zgjidhni datën!"/>
<!--        <div :class="`text-primary ${error ? '' : 'invisible'} min-w-max mt-2`">-->
<!--            {{error}}-->
<!--        </div>-->
    </div>
</template>
<script setup lang="ts">
import InputSkeleton from "~/components/InputSkeleton.vue";
import {format} from "date-fns";
import Tooltip from "~/components/Tooltip.vue";

const mainStore = useMainStore();
const {displayNights} = useUtils();
const {$api} = useNuxtApp()

const currNights = ref<number[]>([]);
const triggerError = ref<number>(0);
const showError = ref(false);
const show = ref(false);

const showNights = () => {
    if(mainStore.selectedDate){
        show.value = true;
    } else {
        triggerError.value++
    }
}
const hideNights = () => {
    show.value = false;
}
const handleSelect = (night: number) => {
    mainStore.selectedNights = night;
    hideNights();
}

const handleTooltipRemoved = () => {
    showError.value = false;
}

const getAvailableNights = async (newDate: any) => {
    if(mainStore.selectedDestination?.pivot.live_search){
        currNights.value = [];
        for(let x = 1; x <= 15; x++){
            currNights.value.push(x);
        }
        return;
    }
    try {
        const response = await $api.post('/packages/available-nights', {
            checkin_date: format(newDate, 'yyyy-MM-dd'),
            origin_id: mainStore.selectedOrigin,
            destination_id: mainStore.selectedDestination
        })

        currNights.value = response.data;
    } catch (e) {
        currNights.value = []
    }
}

onMounted(() => {
    if(mainStore.selectedDate && !currNights.value.length) {
        getAvailableNights(mainStore.selectedDate);
    }
})

watch(() => mainStore.selectedDate, (newDate) => {
    getAvailableNights(newDate)
})

watch(triggerError, () => {
    showError.value = true;
})
</script>
