<template>
    <div class="relative w-full" ref="searchSelect">
        <input type="text" :id="id"
               autocomplete="off"
               :class="[`w-full font-bold text-secondary h-[50px] sm:h-[70px] text-center`, {'rounded-b-[0px] relative': listState}]"
               :value="modelValue"
               @focus="handleInputFocus"
               :placeholder="placeholder"
               @input="(e) => emit('update:modelValue', e.currentTarget.value)">
        <transition name="slide-fade">
            <div v-show="listState" :class="['absolute w-full z-10 bg-white shadow-[0_0_5px_0] shadow-gray-normal rounded-b-[10px] overflow-hidden max-h-[250px] overflow-y-auto']">
                <div v-for="(option, idx) in filteredOptions" @click="handleSelect(option)" class="hover:bg-secondary/20 cursor-pointer">
                    <div class="px-5 py-4">
                        <slot class="cursor-pointer" name="option" v-bind="option">
                            {{option[displayKey]}}
                        </slot>
                    </div>
                    <hr v-if="filteredOptions.length > idx + 1">
                </div>
            </div>
        </transition>
        <tooltip class="min-w-max" :trigger="showError" :timeout="1000" theme="error" :message="error"/>
    </div>
</template>
<script setup lang="ts">
import Tooltip from "~/components/Tooltip.vue";
const emit = defineEmits(['update:modelValue', 'focus', 'focusout', 'select']);
const props = defineProps<{
    modelValue: string
    placeholder?: string
    name?: string
    id?: string
    position?: string
    displayKey?: string
    filterKey?: string | string[]
    options: any
    selected: any
    error?: string
}>();

const {multipleSearchFilter} = useUtils();
const listState = ref<Boolean>(false);
const searchSelect = ref(null)
const showError = ref<number>(0);

const hideList = () => {
    listState.value = false;
    emit('focusout');
}
const showList = () => {
    listState.value = true
}

onClickOutside(searchSelect, hideList)

const filteredOptions = computed(() => {
    if(props.modelValue && props.filterKey) {
        const search = props.modelValue.toLowerCase();
        return multipleSearchFilter(props.options, props.filterKey, search);
    }
    return props.options;
})

const handleInputFocus = (e: FocusEvent) => {
    if(props.error) {
        showError.value++;
    } else {
        showList();
        emit('focus', e);
    }
}
const handleSelect = (option: Origin) => {
    emit('select', option);
    hideList();
}

// watch(() => props.error, (value) => {
//     if(!value) showError.value = false;
// })
</script>

<style scoped>
input{
    padding: 0 10px;
}

</style>
<style lang="postcss">
.origin input {
    @apply rounded-[10px] sm:rounded-[10px_0_0_10px] shadow-[0_0_5px_0] shadow-gray-normal sm:shadow-none
}

.destination input {
    @apply rounded-[10px] sm:rounded-[0_10px_10px_0] shadow-[0_0_5px_0] shadow-gray-normal sm:shadow-none
}
</style>
