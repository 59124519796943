<template>
    <div>
        <div class="grid grid-cols-1 gap-5 sm:gap-0 sm:grid-cols-2 sm:shadow-[0_0_5px_0] sm:shadow-gray-normal rounded-[10px] travelPlaces">
                <search-select
                    v-model="originSearch"
                    name="origin"
                    :filter="true"
                    :selected="selectedOrigin"
                    :options="allOrigins"
                    id="nisja"
                    class="origin"
                    :filterKey="['name', 'country']"
                    :placeholder="selectedOrigin?.name || 'Nisja'"
                    @select="handleOriginSelect"
                    @focus="handleGetOrigins"
                    @focusout="handleOriginFocusOut"
                >
                    <template #option="{country, name}">
                        <div class="flex justify-between items-center">
                            <div class="font-bold text-lg text-secondary">{{name}}</div>
                            <div class="text-secondary">{{country}}</div>
                        </div>
                    </template>
                </search-select>

    <!--        <div class="mb-5 hidden sm:flex sm:mt-3 sm:mb-0 sm:col-span-1 flex-col justify-center sm:justify-start items-center">-->
    <!--            <nuxt-icon name="arrow" class="text-primary text-2xl [&>svg]:rotate-180"/>-->
    <!--            <nuxt-icon name="arrow" class="text-gray-normal text-2xl"/>-->
    <!--        </div>-->
            <search-select
                :selected="selectedDestination"
                class="destination separatorLeftLine"
                name="destination"
                @select="handleDestinationSelect"
                :options="mainStore.allDestinations"
                :placeholder="selectedDestination?.name || 'Mbërritja'"
                id="mberritja"
                :error="selectedOrigin ? '' : 'Ju lutem zgjidhni nisjen!'"
                :filterKey="['name', 'country']"
                v-model="destinationSearch"
                @focus="handleDestFocus"
                @focusout="handleDestinationFocusOut"
            >
                <template #option="{country, name}">
                    <div class="flex justify-between items-center">
                        <div class="font-bold text-lg text-secondary">{{name}}</div>
                        <div class="text-secondary">{{country}}</div>
                    </div>
                </template>
            </search-select>
        </div>
    </div>
</template>
<script setup lang="ts">
import SearchSelect from "~/components/SearchSelect.vue";

const mainStore = useMainStore();
// const {$api} = useNuxtApp();
// const {handleQueryValidate} = useQueryValidator()

const { selectedOrigin, selectedDestination, originSearch, destinationSearch, allOrigins } = storeToRefs(mainStore)
const { actSetOrigin, actSetDestination, actGetOrigins, actGetDestinations } = mainStore


const isLiveSearch = computed(() => {
    return !!mainStore.selectedDestination?.pivot.live_search
})


const resetDateAndNights = () => {
    if(isLiveSearch) return
    mainStore.selectedDate = null;
    mainStore.selectedNights = null;
}
const handleGetOrigins = async () => {
    originSearch.value = ''
    if(!mainStore.allOrigins.length) {
        await actGetOrigins();
    }

}
const handleSetDestinations = () => {
    if(selectedOrigin.value)
        actGetDestinations();
}
const handleOriginSelect = (origin: Origin) => {
    if(origin.id === mainStore.selectedOrigin?.id) return;
    actSetOrigin(origin);
    originSearch.value = origin.name;
    handleSetDestinations();
    actSetDestination(null); //resets destinations
    destinationSearch.value = '';
    resetDateAndNights();
}

const handleDestinationSelect = (destination: Destination) => {
    actSetDestination(destination);
    destinationSearch.value = destination.name;
    resetDateAndNights();
}

const handleOriginFocusOut = () => {
    if(selectedOrigin.value)
        originSearch.value = selectedOrigin.value.name;
}

const handleDestFocus = async (e: FocusEvent) => {
    // @ts-ignore
    const {y} = e.target?.getBoundingClientRect();
    if(y > 0 && window.innerWidth <=640) {
        // setTimeout(() => {
            scrollTo({
                top: y - 80,
                behavior: 'smooth'
            })
        // },150)
    }
    destinationSearch.value = ''
}

const handleDestinationFocusOut = () => {
    if(selectedDestination.value)
        destinationSearch.value = selectedDestination.value.name;
    else
        destinationSearch.value = '';
}
</script>

<style>
.destination{
    position: relative;
}

.travelPlaces .errorDiv{
    position: absolute;
}
</style>
