<template>
    <div class="relative">
        <SearchTypes v-if="showTypes"/>
        <div class="grid grid-cols-3 sm:grid-cols-6 lg:grid-cols-11 gap-4 sm:gap-y-4 gap-x-2.5 p-4 sm:p-8 bg-gradient-to-r rounded-[20px] from-white/90 to-white/80 w-full backdrop-blur-[3px] relative z-20 ">
            <travel-places class="col-span-3 sm:col-span-6 lg:mb-0 lg:col-span-4"/>
            <travel-calendar class="col-span-2 sm:col-span-4 lg:col-span-2"/>
            <travel-nights class="col-span-1 sm:col-span-2 lg:col-span-1"/>
            <travelers-dropdown class="col-span-3 sm:col-span-4 lg:col-span-2"/>
            <div class="col-span-3 sm:col-span-2 lg:col-span-2 relative">
                <tooltip class="min-w-max" :trigger="showErrorMessage" :timeout="1000" theme="error" message="Plotesoni te gjitha fushat!"/>
                <button-default @click="handlePackageSearch" :disabled="!loadingInactive" class="w-full h-[50px] sm:h-[70px] justify-center items-center lg:mb-0 flex gap-2">
<!--                    <div class="min-w-[20px] h-full relative">-->
<!--                        <transition-group name="fade">-->
<!--                            <nuxt-icon v-if="loadingInactive" name="search" class="text-lg absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" filled/>-->
<!--                            <div v-else class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">-->
<!--                                <Spinner />-->
<!--                            </div>-->
<!--                        </transition-group>-->
<!--                    </div>-->
                    <div class="flex gap-2 items-center">
                        Kërko
                        <font-awesome-icon :icon="['fas', 'plane']"/>
                        +
                        <font-awesome-icon :icon="['fas', 'hotel']"/>
                    </div>
                </button-default>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import TravelPlaces from "~/components/searchModule/TravelPlaces.vue";
import TravelNights from "~/components/searchModule/TravelNights.vue";
import ButtonDefault from "~/components/ButtonDefault.vue";
import Tooltip from "~/components/Tooltip.vue";
import TravelCalendar from "~/components/searchModule/TravelCalendar.vue";
import Spinner from "~/components/Spinner.vue";
import TravelersDropdown from "~/components/searchModule/TravelersDropdown.vue";
// import SearchTypes from "~/components/SearchTypes.vue";

const SearchTypes = defineAsyncComponent(() => import('../SearchTypes.vue'))
defineProps(['showTypes'])
const emit = defineEmits(['search'])

const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();
const showErrorMessage = ref(0)

const loadingInactive = computed(() => !mainStore.loadingPackages || route.path.split('-')[0] !== '/search')

const handlePackageSearch = async () => {
    if(!mainStore.getSearchParams){
        showErrorMessage.value++;
    } else {
        //refresh param is used to trigger the search page route watcher to get
        //packages when search params are not changed,
        //acting like a refresh
        const {refresh} = route.query;
        let refreshValue = Number(refresh);
        if(!isNaN(refreshValue)){
            refreshValue = refreshValue + 1;
        } else {
            refreshValue = 0;
        }
        const originToRoute = mainStore.selectedOrigin?.name.toLowerCase().replaceAll(' ', '-')
        const destinationToRoute = mainStore.selectedDestination?.name.toLowerCase().replaceAll(' ', '-')

        const {origin, destination} = route.params

        const placesChanged = origin  !== originToRoute || destination !== destinationToRoute;
        if(route.path.includes('search') && !placesChanged){
            console.log('searching')
            mainStore.actLiveSearch();
            mainStore.selectedPage = 1;
            mainStore.actResetFilters();
        }

        mainStore.actSetCurrSearchParams();
        emit('search')
        window.scrollTo(0,0)

        const routerObject  = {
            path: `/search-${originToRoute}-to-${destinationToRoute}`,
            query: {
                ...mainStore.getSearchParams,
                ...(placesChanged ? {batch_id: null} : {}),
                refresh: refreshValue,
                page: 1
            }
        }

        if(route.path.includes('search')){
            await router.replace(routerObject)
        } else {
            await router.push(routerObject)
        }
    }
}
</script>

<style scoped>

</style>
