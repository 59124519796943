<template>
    <div class="relative">
        <input-skeleton :class="['relative cursor-pointer !p-0', {'border border-primary': error}]" v-click-outside="hideCalendar">
            <div @click="showCalendar" class="w-full h-full flex items-center justify-center">
                {{ mainStore.selectedDate?.toDateString() || 'Data e nisjes'}}
            </div>
            <transition name="slide-fade">
                <div v-if="show" :class="['absolute top-[60px] sm:top-[80px] z-20 left-0 w-[calc(100vw-60px)] sm:w-full']">
                    <transition-group name="page">
                        <div v-if="loadingAvailableDates" class="rounded-md overflow-hidden w-full min-w-[252px] h-[270px] bg-white z-[10] absolute">
                            <div class="w-full h-full animate-pulse bg-gray-normal z-[10]"></div>
                        </div>
                        <div v-else>
                            <CDatePicker :calendarIsOpen="show" @valueUpdated="hideCalendar"/>
                        </div>
                    </transition-group>
                </div>
            </transition>
        </input-skeleton>
        <tooltip class="min-w-max" :trigger="showError" :timeout="1000" theme="error" :message="error"/>

        <!--        <div :class="`text-primary ${error ? '' : 'invisible'} min-w-max mt-2`">-->
<!--            {{error || 'error'}}-->
<!--        </div>-->
    </div>
</template>
<script setup lang="ts">
    import InputSkeleton from "~/components/InputSkeleton.vue";
    import CDatePicker from "~/components/CalendarLoader.vue"
    import Tooltip from "~/components/Tooltip.vue";
    // const CDatePicker = defineAsyncComponent(() => import('../CalendarLoader.vue'))

    const mainStore = useMainStore();
    const show = ref<boolean>(false);
    const showError = ref(0);
    const loadingAvailableDates = ref(true);
    const error = ref('');
    const hideCalendar = () => {
        show.value = false;
        loadingAvailableDates.value = true;
        // new Date().toLocaleDateString()
    }


    const isLiveSearch = computed(() => {
        return !!mainStore.selectedDestination?.pivot.live_search
    })


    const showCalendar = async () => {
        if(mainStore.selectedDestination) {
            if(show.value) return;
            show.value = true;
            if(isLiveSearch.value) {
                loadingAvailableDates.value = false;
                return
            }
            await mainStore.actGetAvailableDates({});
            // moveToAvailable();
            setTimeout(() => {
                loadingAvailableDates.value = false;
            },0)
        } else {
            showError.value++
            error.value = 'Ju lutem zgjidhni destinacionin!'
            setTimeout(() => {
                error.value = '';
            }, 1000)
        }
    }
</script>
<style scoped>

</style>
