<template>
    <DatePicker
        v-model="mainStore.selectedDate"
        locale="sq-AL"
        ref="travelCalendar"
        :expanded="true"
        :attributes="attributes"
        mode="date"
        color="green"
        :min-date="minDate"
        :disabled-dates="disabledDates"
        @update:pages="handlePageUpdate"
        @update:modelValue="(e) => emit('valueUpdated', e)"
    />
</template>
<script setup lang="ts">
import 'v-calendar/style.css';
import { DatePicker } from 'v-calendar';
const props = defineProps<{calendarIsOpen: boolean}>()
const emit = defineEmits(['valueUpdated', 'pageUpdated'])

const {getAllDatesInMonth} = useUtils();
const mainStore = useMainStore();
const show = ref<boolean>(false);
const currMonth = ref<number | null>(null);
const currYear = ref<number | null>(null);
const travelCalendar = ref(null);
const minDate = ref(new Date())
const moveToAvailable = () => {
    if(mainStore.availableDates.length){
        const currAvail = new Date(mainStore.availableDates[0]);
        minDate.value = currAvail;
        const month = currAvail.getMonth() + 1;
        const year = currAvail.getFullYear();
        currMonth.value = month;
        currYear.value = year;
        // travelCalendar.value?.move({month, year});
    }
}

const attributes = computed(() => {
    return mainStore.availableDates.map((d) => {
        return {
            highlight: {
                color: 'blue',
                fillMode: 'light',
            },
            dates: new Date(d),
        }
    })
})

const isLiveSearch = computed(() => {
    return !!mainStore.selectedDestination?.pivot.live_search
})

const disabledDates = computed(() => {
    if(isLiveSearch.value){
        return []
    }
    if(currYear.value && currMonth.value){
        const datesOfMonth = getAllDatesInMonth(currYear.value, currMonth.value);
        if(mainStore.availableDates.length && currYear.value && currMonth.value){
            return datesOfMonth.filter((date) => {
                return mainStore.availableDates.findIndex(d => new Date(d).getDate() === date.getDate()) < 0;
            })
        }
        return datesOfMonth;
    }
    const today = new Date();
    return getAllDatesInMonth(today.getFullYear(), today.getMonth() + 1);
})

const handlePageUpdate = async (page: any) => {
    if(isLiveSearch.value) return;

    const {month, year} = page[0];
    if(month !== currMonth.value || year !== currYear.value){
        currMonth.value = month;
        currYear.value = year;
        // loadingAvailableDates.value = true;
        await mainStore.actGetAvailableDates({month, year});
        // loadingAvailableDates.value = false;
    }
}

watch(() => props.calendarIsOpen, () => {
    console.log('calendar toggled');
    moveToAvailable();
})
</script>

<style>
.vc-title, .vc-weekdays{
    text-transform: capitalize;
}
</style>
